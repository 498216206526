<script lang="ts" setup>
import { computed } from 'vue'
import { watchOnce } from '@vueuse/core'
import { useField } from 'vee-validate'
import { PhaseCategorySetEnum, OpportunityPriorityEnum, DataProductTypeEnum, EntityCoreTypeEnum } from '@prisma/client'

import { usePhases } from '@/composables/data/phase'
import { usePersons } from '@/composables/data/person'
import { useProfileQueries } from '@/queries/profile'
import { useKeywords } from '@/composables/data/keyword'
import { useTeams } from '@/composables/data/team'
import { useCustomProperties } from '@/composables/data/customProperties'
import { useDataProductsEnabledForCustomProperty } from '@/composables/data/data-product-type'

import DSelectorPriority from '@/components/DSelectorPriority.vue'
import DSelectorPerson from '@/components/DSelectorPerson.vue'
import DSelectorEntityPhase from '@/components/DSelectorEntityPhase.vue'
import DSelectorDataProductType from '@/components/DSelectorDataProductType.vue'
import DSelectorKeyword from '../DSelectorKeyword.vue'
import DSelectorTeams from '../DSelectorTeams.vue'
import DSelectorPeople from '../DSelectorPeople.vue'
import DSelectorCustomProperty from '../DSelectorCustomProperty.vue'
import { useMetricTypes } from '@/composables/data/metricType'
import DSelectorMetric from '@/modules/opportunity/components/DSelectorMetric.vue'
import DSelectorBusinessGoal from '@/modules/opportunity/components/DSelectorBusinessGoal.vue'
import { useBusinessGoals } from '@/composables/data/businessGoal'

const props = defineProps<{
    entityType: EntityCoreTypeEnum
}>()
const isOpportunity = computed(() => props.entityType === EntityCoreTypeEnum.Opportunity)

const { get: getCurrentUser } = useProfileQueries()

const { phases: phasesOpportunity, defaultPhase: defaultPhaseOpportunity } = usePhases(
    PhaseCategorySetEnum.OpportunityPhaseCategoryEnum
)
const { phases: phasesDataProduct, defaultPhase: defaultPhaseDataProduct } = usePhases(
    PhaseCategorySetEnum.ProductPhaseCategoryEnum
)

const { nonExternalPersons, allPersons } = usePersons()
const { data: currentUser } = getCurrentUser()

const { keywords } = useKeywords()
const { teams: businessTeams } = useTeams('business')
const { teams: dataTeams } = useTeams('data')
const { customProperties } = useCustomProperties()
const { metricTypes } = useMetricTypes()
const { businessGoals } = useBusinessGoals()

const attributeId = computed(() => customPropertyId.value)
const { enabledProductsItems } = useDataProductsEnabledForCustomProperty(attributeId)

const hasLoadedDefaults = computed(
    () => !!defaultPhaseOpportunity.value && !!defaultPhaseDataProduct.value && !!currentUser.value
)

const { value: dataProductType } = useField<DataProductTypeEnum>('dataProductType')
const { value: phaseId, setValue: setPhaseId } = useField<string>('phaseId')
const { value: priority } = useField<OpportunityPriorityEnum>('priority')
const { value: ownerId, setValue: setOwnerId } = useField<string>('ownerId')
const { value: keywordIds } = useField<string[] | undefined>('keywordIds')
const { value: businessTeamIds } = useField<string[] | undefined>('businessTeamIds')
const { value: dataTeamIds } = useField<string[] | undefined>('dataTeamIds')
const { value: customPropertyId } = useField<string | undefined>('customProperties.0.id')
const { value: customPropertyValueIds } = useField<string[] | undefined>('customProperties.0.valueIds')
const { value: metricIds } = useField<string[] | undefined>('metricIds')
const { value: businessGoalIds } = useField<string[] | undefined>('businessGoalIds')
const { value: stakeholderIds } = useField<string[] | undefined>('stakeholderIds')

const customProperty = computed(() => customProperties.value.find((da) => da.id === customPropertyId.value))

function resetFields() {
    if (hasLoadedDefaults.value) {
        _resetFields()
        return
    }

    watchOnce(hasLoadedDefaults, _resetFields)
}

function _resetFields() {
    const isOpportunity = !dataProductType.value
    const isDataProduct = !isOpportunity

    if (isOpportunity && !phaseId.value) setPhaseId(defaultPhaseOpportunity.value.id)
    if (isDataProduct && !phaseId.value) setPhaseId(defaultPhaseDataProduct.value.id)
    if (!ownerId.value) setOwnerId(currentUser.value!.id)
}

defineExpose({ resetFields })
</script>
<template>
    <div v-if="hasLoadedDefaults" class="flex flex-wrap gap-1 [&>*]:max-w-[280px]">
        <DSelectorEntityPhase v-if="isOpportunity" v-model="phaseId" :phases="phasesOpportunity" button-type="common" />
        <DSelectorEntityPhase v-else v-model="phaseId" :phases="phasesDataProduct" button-type="common" />

        <DSelectorPriority v-if="isOpportunity" v-model="priority" :placeholder="$t(`priority`)" button-type="common" />
        <DSelectorPriority
            v-else
            v-model="priority"
            :placeholder="$t(`priority`)"
            :type="EntityCoreTypeEnum.DataProduct"
            button-type="common"
        />

        <DSelectorPerson
            v-model="ownerId"
            :people="nonExternalPersons"
            :placeholder="$t('components.SelectorPerson.owner')"
            :not-selected-label="$t('components.SelectorPerson.noOwner')"
            button-type="common"
        />

        <DSelectorDataProductType
            v-if="!isOpportunity"
            v-model="dataProductType"
            :enabled-products-items="enabledProductsItems"
            button-type="common"
        />

        <DSelectorKeyword
            v-if="keywordIds"
            v-model="keywordIds"
            :keywords
            button-type="common"
            :placeholder="$t('keyword')"
        />

        <DSelectorTeams
            v-if="businessTeamIds"
            v-model="businessTeamIds"
            display-type="button"
            :teams="businessTeams"
            :placeholder="$t('businessTeam')"
            button-type="common"
            team-type="business"
        />

        <DSelectorTeams
            v-if="dataTeamIds"
            v-model="dataTeamIds"
            :teams="dataTeams"
            display-type="button"
            :placeholder="$t('dataProductTeam')"
            button-type="common"
            team-type="data"
        />

        <DSelectorPeople
            v-if="stakeholderIds"
            v-model="stakeholderIds"
            :people="allPersons"
            button-type="common"
            :placeholder="$t('stakeholders')"
        />

        <DSelectorCustomProperty
            v-if="customProperty"
            v-model="customPropertyValueIds"
            :custom-property="customProperty"
            button-type="common"
        />

        <DSelectorBusinessGoal
            v-if="businessGoalIds"
            v-model="businessGoalIds"
            :business-goals="businessGoals"
            :placeholder="$t('businessGoals')"
            button-type="common"
        />

        <DSelectorMetric
            v-if="metricIds"
            v-model="metricIds"
            :metrics="metricTypes"
            :placeholder="$t('metrics')"
            button-type="common"
        />
    </div>
</template>
