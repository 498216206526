import { EntityTypeEnum, EntityCoreTypeEnum } from '@prisma/client'
import { useI18n } from 'vue-i18n'

export const typeToPrefix: Record<EntityTypeEnum | EntityCoreTypeEnum, string> = {
    [EntityTypeEnum.Opportunity]: 'op',
    [EntityTypeEnum.SmartProduct]: 'sp',
    [EntityTypeEnum.AnalyticsProduct]: 'ap',
    [EntityTypeEnum.DataAsset]: 'da',
    [EntityTypeEnum.TechProduct]: 'tp',
    [EntityCoreTypeEnum.DataProduct]: 'dp',
}

export const entityTypeInfo = {
    [EntityTypeEnum.Opportunity]: {
        icon: 'hexagonal-pyramid',
        borderColor: 'border-amber-200',
    },
    [EntityTypeEnum.AnalyticsProduct]: {
        icon: 'hexagonal-prism',
        borderColor: 'border-emerald-200',
    },
    [EntityTypeEnum.DataAsset]: {
        icon: 'hexagon',
        borderColor: 'border-indigo-200',
    },
    [EntityTypeEnum.TechProduct]: {
        icon: 'cpu',
        borderColor: 'border-primarySky-200',
    },
    [EntityTypeEnum.SmartProduct]: {
        icon: 'assembly',
        borderColor: 'border-fuchsia-200',
    },
    [EntityCoreTypeEnum.DataProduct]: {
        icon: 'layers-linked',
        borderColor: 'border-cyan-200',
    },
}

export const useEntityInfo = (type: EntityTypeEnum | EntityCoreTypeEnum) => {
    return entityTypeInfo[type]
}

export function useEntityTypeMapping() {
    const { t } = useI18n()
    return {
        mapLabel(entityType: EntityTypeEnum) {
            return t(`entityTypes.${entityType}`)
        },
        mapIcon(entityType: EntityTypeEnum) {
            return entityTypeInfo[entityType]?.icon
        },
    }
}
