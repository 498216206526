import type { App } from 'vue'

import IconPriority from '../components/custom-icons/IconPriority.vue'
import IconPriorityUrgent from '../components/custom-icons/IconPriorityUrgent.vue'
import IconPriorityHigh from '../components/custom-icons/IconPriorityHigh.vue'
import IconPriorityMedium from '../components/custom-icons/IconPriorityMedium.vue'
import IconPriorityLow from '../components/custom-icons/IconPriorityLow.vue'
import IconPhasePostponed from '../components/custom-icons/IconPhasePostponed.vue'
import IconFilterCustom from '@/components/custom-icons/IconFilterCustom.vue'
import IconJira from '@/components/custom-icons/IconJira.vue'
import IconConfluence from '@/components/custom-icons/IconConfluence.vue'
import IconDelight from '@/components/custom-icons/IconDelight.vue'

import {
    IconAdjustments,
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconArrowRight,
    IconArrowsJoin2,
    IconArrowsSplit2,
    IconArrowsExchange,
    IconArrowUpRight,
    IconUserCircle,
    IconAt,
    IconBell,
    IconBold,
    IconCaretDown,
    IconCaretUp,
    IconCaretUpDown,
    IconChartDots2,
    IconChartHistogram,
    IconChartLine,
    IconCheck,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconCircle,
    IconCircleArrowRight,
    IconCircleCheck,
    IconCircleFilled,
    IconCircleMinus,
    IconCirclePlus,
    IconCircleX,
    IconClearFormatting,
    IconCpu,
    IconDatabase,
    IconDeviceDesktopAnalytics,
    IconDots,
    IconDotsVertical,
    IconExternalLink,
    IconFileCode,
    IconFilterFilled,
    IconHash,
    IconHeading,
    IconHeart,
    IconHeartFilled,
    IconHelpSquare,
    IconHexagonalPyramid,
    IconHexagonalPrism,
    IconHexagon,
    IconInbox,
    IconInfinity,
    IconInfoCircle,
    IconItalic,
    IconLayoutGrid,
    IconLayoutList,
    IconLayoutRows,
    IconLayoutSidebarLeftCollapse,
    IconLayoutSidebarLeftExpand,
    IconLayoutSidebarRightCollapse,
    IconLayoutSidebarRightExpand,
    IconLifebuoy,
    IconLink,
    IconList,
    IconListNumbers,
    IconLoader,
    IconLogout,
    IconMenu2,
    IconMoodNervous,
    IconMoon,
    IconPencil,
    IconPlus,
    IconSearch,
    IconSettings,
    IconShieldCheck,
    IconStar,
    IconStarFilled,
    IconTable,
    IconTargetArrow,
    IconThumbDown,
    IconThumbDownFilled,
    IconThumbUp,
    IconThumbUpFilled,
    IconTrash,
    IconTrashX,
    IconUnderline,
    IconUser,
    IconUserPlus,
    IconUserMinus,
    IconUsers,
    IconLineDashed,
    IconX,
    IconSparkles,
    IconChartRadar,
    IconDoorExit,
    IconCalendar,
    IconBuildingSkyscraper,
    IconUsersGroup,
    IconGripVertical,
    IconCircleDashed,
    IconStrikethrough,
    IconListCheck,
    IconCircleHalf,
    IconCircleHalf2,
    IconCircleDashedX,
    IconMapPinPlus,
    IconEditCircle,
    IconPointFilled,
    IconBackspace,
    IconWindowMaximize,
    IconBooks,
    IconChartArcs,
    IconGps,
    IconFocus2,
    IconAssembly,
    IconBug,
    IconAlertTriangleFilled,
    IconFileUpload,
    IconLockOpen,
    IconSquares,
    IconFileDescription,
    IconRouteAltRight,
    IconId,
    IconBubbleText,
    IconLayersLinked,
    IconAlphabetLatin,
    IconDiamond,
    IconTrophy,
    IconListDetails,
    IconRulerMeasure,
    IconScale,
    IconUserStar,
    IconLayersOff,
    IconClipboardTypography,
    IconCalendarPlus,
    IconCalendarRepeat,
    IconWand,
    IconPencilCog,
    IconCellSignal1,
    IconGitPullRequest,
    IconSend,
    IconTextSize,
    IconToggleLeft,
    IconCoins,
    IconPercentage,
    IconSelectAll,
    IconBuilding,
    IconBrandGoogle,
    IconBrandNotion,
    IconBrandAws,
    IconBrandMysql,
    IconSql,
    IconBrandMongodb,
    IconBrandDatabricks,
    IconBrandDocker,
    IconBrandAzure,
    IconBrandGit,
    IconBrandGithub,
    IconBrandPython,
    IconAi,
    IconAlertTriangle,
    IconApi,
    IconBrandApple,
    IconArchive,
    IconArticle,
    IconAtom,
    IconBolt,
    IconBook,
    IconBook2,
    IconBookmark,
    IconBox,
    IconBrandAndroid,
    IconBulb,
    IconCamera,
    IconCloud,
    IconChartBar,
    IconChartCovariate,
    IconChartDonut,
    IconChartGridDots,
    IconChartPie4,
    IconChartScatter,
    IconCheckbox,
    IconCodeCircle2,
    IconCsv,
    IconDeviceDesktop,
    IconDeviceMobile,
    IconDiamonds,
    IconFile,
    IconFlame,
    IconFolder,
    IconHome,
    IconLock,
    IconMail,
    IconMessage2,
    IconPhoto,
    IconPresentationAnalytics,
    IconPrinter,
    IconPuzzle,
    IconShield,
    IconSitemap,
    IconStack2,
    IconWorld,
    IconCopyPlus,
} from '@tabler/icons-vue'

export const customIcons = {
    IconPriority,
    IconPriorityUrgent,
    IconPriorityHigh,
    IconPriorityMedium,
    IconPriorityLow,
    IconPhasePostponed,
    IconFilterCustom,
    IconJira,
    IconConfluence,
    IconDelight,
}

export const tablerIcons = {
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconArrowRight,
    IconArrowsJoin2,
    IconArrowsSplit2,
    IconArrowsExchange,
    IconArrowUpRight,
    IconAssembly,
    IconAt,
    IconBell,
    IconBold,
    IconCaretDown,
    IconCaretUp,
    IconCaretUpDown,
    IconChartDots2,
    IconChartHistogram,
    IconChartLine,
    IconUserCircle,
    IconCheck,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconCircle,
    IconCircleArrowRight,
    IconCircleCheck,
    IconCircleFilled,
    IconCircleMinus,
    IconCirclePlus,
    IconLineDashed,
    IconCircleX,
    IconClearFormatting,
    IconCpu,
    IconDatabase,
    IconDeviceDesktopAnalytics,
    IconDots,
    IconDotsVertical,
    IconExternalLink,
    IconFileCode,
    IconFilterFilled,
    IconHash,
    IconHeading,
    IconHeart,
    IconHeartFilled,
    IconHelpSquare,
    IconHexagonalPyramid,
    IconHexagonalPrism,
    IconHexagon,
    IconInbox,
    IconInfinity,
    IconInfoCircle,
    IconItalic,
    IconLayoutGrid,
    IconLayoutRows,
    IconLifebuoy,
    IconLink,
    IconList,
    IconListNumbers,
    IconLoader,
    IconLogout,
    IconMenu2,
    IconMoodNervous,
    IconMoon,
    IconPencil,
    IconPlus,
    IconSearch,
    IconSettings,
    IconShieldCheck,
    IconStar,
    IconStarFilled,
    IconTable,
    IconTargetArrow,
    IconThumbDown,
    IconThumbDownFilled,
    IconThumbUp,
    IconThumbUpFilled,
    IconTrash,
    IconTrashX,
    IconUnderline,
    IconUser,
    IconUserPlus,
    IconUserMinus,
    IconUsers,
    IconX,
    IconSparkles,
    IconChartRadar,
    IconAdjustments,
    IconDoorExit,
    IconLayoutSidebarLeftCollapse,
    IconLayoutSidebarLeftExpand,
    IconLayoutSidebarRightCollapse,
    IconLayoutSidebarRightExpand,
    IconLayoutList,
    IconCalendar,
    IconBuildingSkyscraper,
    IconUsersGroup,
    IconGripVertical,
    IconCircleDashed,
    IconStrikethrough,
    IconListCheck,
    IconCircleHalf,
    IconCircleHalf2,
    IconCircleDashedX,
    IconMapPinPlus,
    IconEditCircle,
    IconPointFilled,
    IconBackspace,
    IconWindowMaximize,
    IconBooks,
    IconChartArcs,
    IconGps,
    IconFocus2,
    IconBug,
    IconAlertTriangleFilled,
    IconFileUpload,
    IconLockOpen,
    IconSquares,
    IconFileDescription,
    IconRouteAltRight,
    IconId,
    IconBubbleText,
    IconLayersLinked,
    IconLayersOff,
    IconClipboardTypography,
    IconCalendarPlus,
    IconCalendarRepeat,
    IconWand,
    IconPencilCog,
    IconCellSignal1,
    IconGitPullRequest,
    IconSend,
    IconTextSize,
    IconToggleLeft,
    IconCoins,
    IconPercentage,
    IconSelectAll,
    IconBuilding,
    IconBrandGoogle,
    IconBrandNotion,
    IconBrandAws,
    IconBrandMysql,
    IconSql,
    IconBrandMongodb,
    IconBrandDatabricks,
    IconBrandDocker,
    IconBrandAzure,
    IconBrandGit,
    IconBrandGithub,
    IconBrandPython,
    IconAi,
    IconAlertTriangle,
    IconApi,
    IconBrandApple,
    IconArchive,
    IconArticle,
    IconAtom,
    IconBolt,
    IconBook,
    IconBook2,
    IconBookmark,
    IconBox,
    IconBrandAndroid,
    IconBulb,
    IconCamera,
    IconCloud,
    IconChartBar,
    IconChartCovariate,
    IconChartDonut,
    IconChartGridDots,
    IconChartPie4,
    IconChartScatter,
    IconCheckbox,
    IconCodeCircle2,
    IconCsv,
    IconDeviceDesktop,
    IconDeviceMobile,
    IconDiamonds,
    IconFile,
    IconFlame,
    IconFolder,
    IconHome,
    IconLock,
    IconMail,
    IconMessage2,
    IconPhoto,
    IconPresentationAnalytics,
    IconPrinter,
    IconPuzzle,
    IconShield,
    IconSitemap,
    IconStack2,
    IconWorld,
    IconCopyPlus,
}

export const namedIcons = {
    iconKeyword: IconAlphabetLatin,
    iconValuePotential: IconDiamond,
    iconValueScore: IconChartRadar,
    iconPriority: IconPriority,
    iconPhase: IconCircleDashed,
    iconBusinessGoal: IconTrophy,
    iconCustomProperty: IconListDetails,
    iconOwner: IconUser,
    iconStakeholder: IconUserStar,
    iconBusinessTeam: IconBuildingSkyscraper,
    iconDataProductTeam: IconUsersGroup,
    iconMember: IconUsers,
    iconMetric: IconRulerMeasure,
    iconAssessment: IconScale,
    iconDataProductType: IconSquares,
    iconConfidence: IconCellSignal1,
    iconPriorityNotSet: IconCircle,
    iconAreas: IconBuilding,
}

export const icons = { ...namedIcons, ...tablerIcons, ...customIcons }

export default {
    install(app: App) {
        Object.entries(icons).forEach(([name, component]) => {
            app.component(name, component)
        })
    },
}
