import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { client } from '@/plugins/trpc'

export const getEntityRoutePath = async (
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const entityId = to.params.id as string
    const tab = to.params.tab as string
    const tabPath = tab ? `/${tab}` : ''

    try {
        const { routePath } = await client.entityType.getByEntityId.query({ id: entityId })
        next(`/${routePath}/${entityId}${tabPath}`)
    } catch {
        next('/not-found')
    }
}
