<script lang="ts" setup>
import { computed } from 'vue'
import { DataProductTypeEnum } from '@prisma/client'

import type { SelectorItem } from '@/composables/selector'
import { useDataProductTypes } from '@/composables/data/data-product-type'

import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const props = withDefaults(
    defineProps<{
        modelValue: DataProductTypeEnum
        buttonType?: DButtonType
        placeholder?: string
        testid?: string
        filterPlaceholder?: string
        enabledProductsItems?: SelectorItem[]
    }>(),
    {
        testid: 'd-selector-data-product-type',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value?: string): void
    (name: 'unselect'): void
}>()

const dataProductTypeItems = useDataProductTypes()
const selected = computed(() => dataProductTypeItems.filter((item) => item.id === props.modelValue))

const selectorOptions = computed(() =>
    props.enabledProductsItems && props.enabledProductsItems.length ? props.enabledProductsItems : dataProductTypeItems
)

const onModelUpdate = (item?: SelectorItem[]) => {
    if (!item) return
    emit('update:modelValue', item?.[0].id as DataProductTypeEnum)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="selectorOptions"
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelUpdate"
    >
        <DButton :type="buttonType" :data-testid="testid">
            <BaseIcon v-if="selected[0]?.icon" :icon="selected[0]?.icon" class="text-slate-500" size="md" />
            {{ $t(`entityTypes.${modelValue}`) }}
        </DButton>
    </DSelectorGeneric>
</template>
