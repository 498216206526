<script lang="ts" setup>
import { DataProductTypeEnum, EntityCoreTypeEnum } from '@prisma/client'
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useForm, useField } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import type { Delta } from '@vueup/vue-quill'

import { useHeap } from '@/plugins/heap'
import { useCustomToast } from '@/composables/toast'
import { useCurrentRoute, entityTypeToRouteDeprecated } from '@/composables/router'
import type { TextEditorChangeEvent } from '@/composables/textEditor'
import type { DataProductPayload } from '@/composables/entityViewDataProduct'
import type { OpportunityPayload } from '@/composables/entityViewOpportunity'
import { useDataProductsEnabledForCustomProperty } from '@/composables/data/data-product-type'

import DInputText from '@/components/DInputText.vue'
import DTextEditor from '@/components/DTextEditor.vue'
import DButton from '@/components/DButton.vue'
import DModal from '@/components/DModal.vue'
import LumoBar from '@/components/LumoBar.vue'
import LumoButton from '@/components/lumo/LumoButton.vue'
import DSelectorEntityType from '@/components/DSelectorEntityType.vue'

import ActionBar from './ActionBar.vue'
import LumoActionMenu from '../lumo/LumoActionMenu.vue'

import { useSchema } from './schema'
import { useCreateEntityQueries } from './queries'
import { getTrackEntityCreatedType } from './utils'

const props = withDefaults(
    defineProps<{
        defaultEntityType?: EntityCoreTypeEnum
        disableEntityType?: boolean
        showTriggerButton?: boolean
    }>(),
    {
        disableEntityType: false,
        showTriggerButton: true,
    }
)

const { t } = useI18n()
const toast = useCustomToast()
const router = useRouter()
const { track } = useHeap()
const { opportunitySchema, dataProductSchema } = useSchema()
const { getCurrentRouteEntry } = useCurrentRoute()
const entityQueries = useCreateEntityQueries()

const entityType = ref(getDefaultEntityType())
const isOpportunity = computed(() => entityType.value === EntityCoreTypeEnum.Opportunity)
const dataProductType = ref<DataProductTypeEnum | undefined>()

const validationSchema = computed(() => toTypedSchema(isOpportunity.value ? opportunitySchema : dataProductSchema))
const { handleSubmit, errors, setFieldValue, setErrors } = useForm({
    validationSchema,
})

const { value: label, handleChange: handleChangeLabel } = useField<string>('label', undefined, { initialValue: '' })

const isVisible = ref(false)
const isSaveButtonLoading = ref(false)
const forceLabelError = ref(false)
const hasChanges = ref(false)
const actionBar = ref<InstanceType<typeof ActionBar> | undefined>()
const descriptionEditor = ref<InstanceType<typeof DTextEditor> | undefined>()
const descriptionRef = ref<TextEditorChangeEvent | undefined>()
const defaultValues = ref<DataProductPayload | OpportunityPayload>({})

const propertyId = computed(() => defaultValues.value.customProperty?.propertyId)
const { productsEnabledForCustomProperty } = useDataProductsEnabledForCustomProperty(propertyId)

const { mutateAsync: createOpportunity } = entityQueries.createOpportunity()
const { mutateAsync: createDataProduct } = entityQueries.createDataProduct()

defineExpose({ isVisible, show })

watch(entityType, () => (forceLabelError.value = false))
watch(isOpportunity, () => setFormDefaults())
watch(descriptionRef, (event) => (hasChanges.value = !!event?.length))
watch(label, (newValue) => (hasChanges.value = !!newValue))
watch(forceLabelError, (isError) => {
    if (isError) setErrors({ label: t('lumo.labelErrorMessage') })
})

// Reset the form when the modal is opened and action bar instance is ready
watch([isVisible, actionBar], ([isVisible, actionBar]) => {
    if (!isVisible || !actionBar) return
    entityType.value = getDefaultEntityType()
    setFormDefaults()
})

function show(payload: DataProductPayload | OpportunityPayload = {}) {
    defaultValues.value = payload
    isVisible.value = true
}

function setFormDefaults() {
    dataProductType.value = getDefaultDataProductType()
    setFieldValue('phaseId', defaultValues.value.phaseId ?? '')
    setFieldValue('priority', defaultValues.value.priority ?? 'not_set')
    setFieldValue('ownerId', defaultValues.value.ownerId ?? '')
    setFieldValue('dataProductType', dataProductType.value)
    setFieldValue('keywordIds', getDefaultArrayValue(defaultValues.value.keywordId))
    setFieldValue('businessTeamIds', getDefaultArrayValue(defaultValues.value.businessTeamId))
    setFieldValue('dataTeamIds', getDefaultArrayValue(defaultValues.value.dataTeamId))
    setFieldValue('stakeholderIds', getDefaultArrayValue(defaultValues.value.stakeholderId))
    if ('businessGoalId' in defaultValues.value || 'metricId' in defaultValues.value) {
        setFieldValue('businessGoalIds', getDefaultArrayValue(defaultValues.value.businessGoalId))
        setFieldValue('metricIds', getDefaultArrayValue(defaultValues.value.metricId))
    }
    if (defaultValues.value.customProperty) {
        setFieldValue(
            'customProperties.0.valueIds',
            defaultValues.value.customProperty.valueId ? [defaultValues.value.customProperty.valueId] : []
        )
        setFieldValue('customProperties.0.id', defaultValues.value.customProperty.propertyId)
    } else {
        setFieldValue('customProperties', [])
    }
    actionBar.value?.resetFields()
    hasChanges.value = false
}

function getDefaultArrayValue(value: string | null | undefined): string[] | undefined {
    return value === null ? [] : value ? [value] : undefined
}

function getDefaultEntityType() {
    return props.defaultEntityType ?? getCurrentRouteEntry()?.entityType ?? EntityCoreTypeEnum.Opportunity
}

function getDefaultDataProductType() {
    if (isOpportunity.value) return
    if (propertyId.value) {
        const isSmartProductAvailable = productsEnabledForCustomProperty.value.some(
            (product) => product === DataProductTypeEnum.SmartProduct
        )
        return isSmartProductAvailable ? DataProductTypeEnum.SmartProduct : productsEnabledForCustomProperty.value[0]
    }

    return (
        ('dataProductType' in defaultValues.value && defaultValues.value.dataProductType) ||
        getCurrentRouteEntry()?.dataProductType ||
        DataProductTypeEnum.SmartProduct
    )
}

const onSubmit = handleSubmit(async (values) => {
    try {
        isSaveButtonLoading.value = true
        let entityId: string

        // TODO: refactor to type guard isOpportunity ONE-712
        if ('dataProductType' in values) {
            const rsp = await createDataProduct({
                ...values,
                description: descriptionRef.value?.current,
                priority: values.priority,
            })
            toast.showSuccessToast(t('components.CreateEntity.dataProduct.createSuccessToast'))
            entityId = rsp.id
            track('EntityCreated', {
                entityId,
                type: getTrackEntityCreatedType(values.dataProductType),
            })
            router.push(entityTypeToRouteDeprecated(values.dataProductType, entityId))
        } else {
            const rsp = await createOpportunity({
                ...values,
                description: descriptionRef.value?.current,
                priority: values.priority,
            })
            toast.showSuccessToast(t('components.CreateEntity.opportunity.createSuccessToast'))
            entityId = rsp.id
            track('EntityCreated', {
                entityId,
                type: getTrackEntityCreatedType(EntityCoreTypeEnum.Opportunity),
            })
            router.push(entityTypeToRouteDeprecated(EntityCoreTypeEnum.Opportunity, entityId))
        }
    } finally {
        isSaveButtonLoading.value = false
        isVisible.value = false
    }
})
</script>

<template>
    <div>
        <div v-if="showTriggerButton" data-testid="create-entity-button" @click="show()">
            <slot>
                <DButton
                    class="w-full"
                    :label="$t('components.CreateEntity.buttonLabel')"
                    icon="plus"
                    horizontal-alignment="left"
                />
            </slot>
        </div>

        <DModal
            data-testid="create-entity-modal"
            :is-visible="isVisible"
            :has-changes="hasChanges"
            :confirm-label="$t('components.CreateEntity.create')"
            confirm-button-submit
            form-name="createForm"
            :is-confirm-button-loading="isSaveButtonLoading"
            @update:is-visible="isVisible = $event"
            @cancel="forceLabelError = false"
            @confirm="forceLabelError = false"
        >
            <template #header>
                <div class="flex justify-between items-center w-full mr-3">
                    <DSelectorEntityType v-model="entityType" :disabled="disableEntityType" button-type="common" />
                    <LumoBar
                        :label="label"
                        :editor="descriptionEditor"
                        :type="isOpportunity ? EntityCoreTypeEnum.Opportunity : EntityCoreTypeEnum.DataProduct"
                        @trigger-label-error="forceLabelError = true"
                    >
                        <template #default="{ copilotBusy, lumoLabel, validateInputs, hasDescription }">
                            <LumoActionMenu
                                v-if="hasDescription"
                                :type="hasDescription || copilotBusy ? 'lumo' : 'plain'"
                                :loading="copilotBusy"
                                :label="lumoLabel"
                                data-testid="copilot-create-description-from-text"
                                @append="validateInputs({ shouldReplace: false })"
                                @replace="validateInputs({ shouldReplace: true })"
                            />
                            <LumoButton
                                v-else
                                type="lumo"
                                :label="lumoLabel"
                                :loading="copilotBusy"
                                data-testid="copilot-create-description-from-text"
                                @click="validateInputs({ shouldReplace: false })"
                            />
                        </template>
                    </LumoBar>
                </div>
            </template>

            <form id="createForm" @submit="onSubmit">
                <div class="mt-3 flex flex-col gap-3">
                    <DInputText
                        :model-value="label"
                        size="lg"
                        ghost
                        testid="label-input"
                        :placeholder="
                            isOpportunity
                                ? $t(`components.CreateEntity.opportunity.titlePlaceholder`)
                                : $t(`components.CreateEntity.dataProduct.titlePlaceholder`)
                        "
                        :error="errors.label"
                        @update:model-value="handleChangeLabel"
                    />
                    <DTextEditor
                        ref="descriptionEditor"
                        ghost
                        editor-id="create-description"
                        :delta="descriptionRef?.current as Delta"
                        :min-rows="5"
                        :max-rows="10"
                        :placeholder="
                            isOpportunity
                                ? $t(`components.CreateEntity.opportunity.descriptionPlaceholder`)
                                : $t(`components.CreateEntity.dataProduct.descriptionPlaceholder`)
                        "
                        data-testid="description-editor"
                        bounds-value="modal-content"
                        @change="descriptionRef = $event"
                    />

                    <ActionBar ref="actionBar" :entity-type="entityType" />
                </div>
            </form>
        </DModal>
    </div>
</template>
