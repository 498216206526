import { computed, type MaybeRef, toValue, type ComputedRef } from 'vue'
import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import { EntityViewEnum } from '@mindfuel/server/src/common/config'
import { DataProductTypeEnum, CustomPropertyTypeEnum } from '@prisma/client'
import { formatDateLocale } from '../time'
import { useMetricValueFormatter } from '../numberFormat'
import { useI18n } from 'vue-i18n'

export const useCustomProperties = (type?: MaybeRef<EntityViewEnum>) => {
    const {
        data: customProperties,
        isFetching,
        isLoading,
        isRefetching,
        isPending,
    } = useQuery({
        queryKey: ['listAllCustomProperties'],
        queryFn: () => client.customProperties.list.query(),
        initialData: [],
        queries: ['CustomProperty', 'CustomPropertyValue', 'CustomPropertyEnabledFor'],
    })

    const { t } = useI18n()

    const allOrFilteredByEntityType = computed(() => {
        return (
            customProperties.value
                .filter((attr) =>
                    toValue(type) === EntityViewEnum.DataProduct
                        ? attr.entityType !== EntityViewEnum.Opportunity
                        : !type || attr.entityType === toValue(type)
                )
                .map(({ property }) => property)
                // filter out duplicates
                .filter((attr, index, self) => self.findIndex((t) => t.id === attr.id) === index)
                // map valueText in values to label
                .map(({ values, ...attr }) => {
                    let mappedValues = []

                    if (attr.type === CustomPropertyTypeEnum.checkbox) {
                        mappedValues = [
                            { label: t('yes'), value: true, id: 'true' },
                            { label: t('no'), value: false, id: 'false' },
                        ]
                    } else {
                        mappedValues = values
                            .map((i) => i)
                            .sort((a, b) => {
                                if (a.valueNumber && b.valueNumber)
                                    return b.valueNumber.toNumber() - a.valueNumber.toNumber()
                                if (a.valueDate && b.valueDate) return b.valueDate.getTime() - a.valueDate.getTime()
                                return 0
                            })
                            .map(({ valueText, valueDate, valueNumber, valueBoolean, ...value }) => {
                                return {
                                    ...value,
                                    label:
                                        valueText ||
                                        (valueDate && formatDateLocale(valueDate)) ||
                                        (attr.type === 'currency' &&
                                            valueNumber &&
                                            useMetricValueFormatter(valueNumber.toNumber())) ||
                                        (attr.type === 'percentage' && valueNumber && `${valueNumber.toString()} %`) ||
                                        valueNumber?.toString() ||
                                        (valueBoolean ? t('yes') : t('no')) ||
                                        '',
                                }
                            })
                    }

                    return {
                        ...attr,
                        values: mappedValues,
                    }
                })
        )
    })

    function dataProductTypesEnabledForCustomProperty(customPropertyId?: ComputedRef<string | undefined>) {
        return computed(() =>
            customProperties.value
                .filter((attr) => attr.property.id === customPropertyId?.value)
                .filter((attr) => attr.entityType !== EntityViewEnum.Opportunity)
                .map(({ entityType }) => entityType as DataProductTypeEnum)
        )
    }

    return {
        customProperties: allOrFilteredByEntityType,
        dataProductTypesEnabledForCustomProperty,
        isFetching,
        isLoading,
        isRefetching,
        isPending,
    }
}
