import {
    type EntityViewConfig,
    EntityTypeEnum,
    EntityViewConfigTypeEnum,
    EntityViewConfigValueTypeEnum,
} from '@prisma/client'

//
// Common configuration and definitions
//

export const EntityViewPageNameEnum = {
    opportunityList: 'opportunityList',
    dataProductList: 'dataProductList',
    analyticsProductList: 'analyticsProductList',
    dataAssetList: 'dataAssetList',
    techProductList: 'techProductList',
    smartProductList: 'smartProductList',
    opportunityReport: 'opportunityReport',
    dataProductReport: 'dataProductReport',
    businessLineage: 'businessLineage',
} as const
export type EntityViewPageNameEnumType = keyof typeof EntityViewPageNameEnum

export const FilterOperatorEnum = {
    is: 'is',
    isNot: 'isNot',
    includeAnyOf: 'includeAnyOf',
    includeAllOf: 'includeAllOf',
    excludeIfAnyOf: 'excludeIfAnyOf',
    excludeIfAllOf: 'excludeIfAllOf',
    isGreaterThan: 'isGreaterThan',
    isLessThan: 'isLessThan',
    isEqualTo: 'isEqualTo',
    isNotEqualTo: 'isNotEqualTo',
    greaterOrEqualTo: 'greaterOrEqualTo',
    lessOrEqualTo: 'lessOrEqualTo',
    contains: 'contains',
    doesNotContain: 'doesNotContain',
    isEmpty: 'isEmpty',
    isNotEmpty: 'isNotEmpty',
    isBefore: 'isBefore',
    isAfter: 'isAfter',
    isOnOrBefore: 'isOnOrBefore',
    isOnOrAfter: 'isOnOrAfter',
} as const
export type FilterOperatorEnumType = keyof typeof FilterOperatorEnum

export const OptionKeyEnum = {
    order: 'order',
} as const
export type OptionKeyEnumType = keyof typeof OptionKeyEnum

export const OrderOperatorEnum = {
    asc: 'asc',
    desc: 'desc',
} as const
export type OrderOperatorEnumType = keyof typeof OrderOperatorEnum

export const UIKeyEnum = {
    groupBy: 'groupBy',
    layout: 'layout',
    showEmptyGroups: 'showEmptyGroups',
    showUnassignedGroups: 'showUnassignedGroups',
    showOwner: 'showOwner',
    showPhase: 'showPhase',
    showPriority: 'showPriority',
    showValuePotential: 'showValuePotential',
    showValueScore: 'showValueScore',
    showCustomProperty: 'showCustomProperty',
    showAssessment: 'showAssessment',
} as const
export type UIKeyEnumType = keyof typeof UIKeyEnum

export const UIShowPropsEnum = {
    showOwner: 'showOwner',
    showPhase: 'showPhase',
    showPriority: 'showPriority',
    showValuePotential: 'showValuePotential',
    showValueScore: 'showValueScore',
    showCustomProperty: 'showCustomProperty',
    showAssessment: 'showAssessment',
} as const
export type UIShowPropsEnum = keyof typeof UIShowPropsEnum

export const sharedUIDefinitions = {
    [UIKeyEnum.groupBy]: EntityViewConfigValueTypeEnum.string,
    [UIKeyEnum.showEmptyGroups]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showUnassignedGroups]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.layout]: EntityViewConfigValueTypeEnum.string,
    [UIKeyEnum.showOwner]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showPhase]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showPriority]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showValuePotential]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showValueScore]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showCustomProperty]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showAssessment]: EntityViewConfigValueTypeEnum.boolean,
} as const satisfies Record<UIKeyEnumType, EntityViewConfigValueTypeEnum>

type ValueFieldType = 'valueString' | 'valueStringAry' | 'valueNumber' | 'valueBoolean' | 'valueDate'
export const valueTypeToAttributeName: Record<EntityViewConfigValueTypeEnum, ValueFieldType | undefined> = {
    [EntityViewConfigValueTypeEnum.string]: 'valueString',
    [EntityViewConfigValueTypeEnum.string_ary]: 'valueStringAry',
    [EntityViewConfigValueTypeEnum.number]: 'valueNumber',
    [EntityViewConfigValueTypeEnum.boolean]: 'valueBoolean',
    [EntityViewConfigValueTypeEnum.date]: 'valueDate',
    [EntityViewConfigValueTypeEnum.none]: undefined,
} as const

export type EntityViewFilter<Keys> = CheckedKeys<
    keyof Pick<EntityViewConfig, 'key' | 'filterOperator'>,
    {
        key: Keys
        filterOperator: FilterOperatorEnumType
    }
> &
    Pick<EntityViewConfig, 'id' | 'filterId' | ValueFieldType>

export const LayoutEnum = {
    card: 'card',
    list: 'list',
} as const
export type LayoutEnum = keyof typeof LayoutEnum
export type UIOptionGroupBy<GroupKeys> = {
    id: string
    key: (typeof UIKeyEnum)['groupBy']
    valueString: GroupKeys
    filterId: string
}
export type UIOptionShowEmptyGroups = {
    id: string
    key: (typeof UIKeyEnum)['showEmptyGroups']
    valueBoolean: boolean
}
export type UIOptionShowUnassignedGroups = {
    id: string
    key: (typeof UIKeyEnum)['showUnassignedGroups']
    valueBoolean: boolean
}
export type UIOptionLayout = {
    id: string
    key: (typeof UIKeyEnum)['layout']
    valueString: LayoutEnum
}
export type UIOptionShowProps = {
    id: string
    key: UIShowPropsEnum
    valueBoolean: boolean
    filterId?: string
}

export type EntityViewUIOption<GroupKeys> =
    | UIOptionGroupBy<GroupKeys>
    | UIOptionShowEmptyGroups
    | UIOptionShowUnassignedGroups
    | UIOptionLayout
    | UIOptionShowProps

export type EntityViewQueryOption<OrderKeys> = Pick<EntityViewConfig, 'id'> & {
    key: (typeof OptionKeyEnum)['order']
    filterOperator: OrderOperatorEnumType
    valueString: OrderKeys
    filterId?: string
}

export type EntityViewParams<FilterKeys, OrderKeys, GroupKeys> = CheckedKeys<
    EntityViewConfigTypeEnum,
    {
        [EntityViewConfigTypeEnum.filter]: EntityViewFilter<FilterKeys>[]
        [EntityViewConfigTypeEnum.queryOption]: EntityViewQueryOption<OrderKeys>[]
        [EntityViewConfigTypeEnum.uiOption]: EntityViewUIOption<GroupKeys>[]
    }
>

export function isFilter<FilterKeys extends string>(
    filter: object,
    definitions: FilterDefinitionsType<FilterKeys>
): filter is EntityViewFilter<FilterKeys> {
    const isValidFilter =
        'id' in filter &&
        'key' in filter &&
        'filterOperator' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.filterOperator === 'string' &&
        filter.filterOperator in FilterOperatorEnum &&
        filter.key in definitions &&
        filter.filterOperator in definitions[filter.key as FilterKeys]

    if (!isValidFilter) return false

    const valueType: EntityViewConfigValueTypeEnum =
        definitions[filter.key as FilterKeys][filter.filterOperator as FilterOperatorEnumType]!
    const attributeName = valueTypeToAttributeName[valueType]

    if (valueType === EntityViewConfigValueTypeEnum.none) return true
    // Redundant check, but TypeScript doesn't see connection between valueType and attributeName,
    // for valueType other than none, attributeName is always defined
    if (!attributeName) return true

    if (!(attributeName in filter)) return false

    const filterValue = (filter as unknown as Record<typeof attributeName, unknown>)[attributeName]
    return (
        (valueType !== EntityViewConfigValueTypeEnum.string_ary && filterValue !== null) ||
        (valueType === EntityViewConfigValueTypeEnum.string_ary && Array.isArray(filterValue) && !!filterValue.length)
    )
}

export function isQueryOption<OrderKeys extends string>(
    filter: object,
    orderKeys: StringEnum<OrderKeys>
): filter is EntityViewQueryOption<OrderKeys> {
    return (
        'id' in filter &&
        'key' in filter &&
        'filterOperator' in filter &&
        'valueString' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.filterOperator === 'string' &&
        typeof filter.valueString === 'string' &&
        filter.key === OptionKeyEnum.order &&
        filter.filterOperator in OrderOperatorEnum &&
        filter.valueString in orderKeys
    )
}

export function isUIOptionGroupBy<GroupKeys extends string>(
    filter: object,
    groupKeys: StringEnum<GroupKeys>
): filter is UIOptionGroupBy<GroupKeys> {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueString' in filter &&
        'filterId' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueString === 'string' &&
        typeof filter.filterId === 'string' &&
        filter.key === UIKeyEnum.groupBy &&
        filter.valueString in groupKeys
    )
}

export function isUIOptionShowEmptyGroups(filter: object): filter is UIOptionShowEmptyGroups {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueBoolean' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueBoolean === 'boolean' &&
        filter.key === UIKeyEnum.showEmptyGroups
    )
}

export function isUIOptionShowUnassignedGroups(filter: object): filter is UIOptionShowUnassignedGroups {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueBoolean' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueBoolean === 'boolean' &&
        filter.key === UIKeyEnum.showUnassignedGroups
    )
}

export function isUIOptionLayout(filter: object): filter is UIOptionLayout {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueString' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueString === 'string' &&
        filter.key === UIKeyEnum.layout &&
        filter.valueString in LayoutEnum
    )
}

export function isUIOptionShowProps(filter: object): filter is UIOptionShowProps {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueBoolean' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueBoolean === 'boolean' &&
        filter.key in UIShowPropsEnum
    )
}

//
// Opportunities definitions
//

export const OpportunityFilterKeyEnum = {
    keyword: 'keyword',
    valuePotential: 'valuePotential',
    valueScore: 'valueScore',
    priority: 'priority',
    phase: 'phase',
    businessGoal: 'businessGoal',
    owner: 'owner',
    stakeholder: 'stakeholder',
    businessTeam: 'businessTeam',
    dataProductTeam: 'dataProductTeam',
    metric: 'metric',
    area: 'area',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyText: 'customPropertyText',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
    // Dev purpose only
    createdById: 'createdById',
} as const
export type OpportunityFilterKeyEnumType = keyof typeof OpportunityFilterKeyEnum

export const OpportunityOrderEnum = {
    title: 'title',
    valuePotential: 'valuePotential',
    valueScore: 'valueScore',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    lastCreated: 'lastCreated',
    lastUpdated: 'lastUpdated',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
} as const
export type OpportunityOrderEnumType = keyof typeof OpportunityOrderEnum

export const defaultOpportunityOrder = {
    key: OptionKeyEnum.order,
    valueString: OpportunityOrderEnum.valuePotential,
    filterOperator: OrderOperatorEnum.desc,
} as const

export const OpportunityGroupEnum = {
    ungrouped: 'ungrouped',
    keyword: 'keyword',
    priority: 'priority',
    phase: 'phase',
    businessGoal: 'businessGoal',
    owner: 'owner',
    stakeholder: 'stakeholder',
    businessTeam: 'businessTeam',
    dataProductTeam: 'dataProductTeam',
    metric: 'metric',
    area: 'area',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
} as const satisfies TGroupEnumType<OpportunityFilterKeyEnumType>
export type OpportunityGroupEnumType = keyof typeof OpportunityGroupEnum

export const opportunityFilterDefinitions = {
    [OpportunityFilterKeyEnum.valuePotential]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.valueScore]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.businessGoal]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.keyword]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },

    [OpportunityFilterKeyEnum.area]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.metric]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.priority]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [OpportunityFilterKeyEnum.phase]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [OpportunityFilterKeyEnum.customPropertySelect]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyNumber]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyText]: {
        [FilterOperatorEnum.contains]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.doesNotContain]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyDate]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isBefore]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isOnOrBefore]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isAfter]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isOnOrAfter]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyBoolean]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.boolean,
    },
    [OpportunityFilterKeyEnum.owner]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.stakeholder]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.businessTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.dataProductTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    // this is just for testing purposes
    [OpportunityFilterKeyEnum.createdById]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string,
    },
} as const satisfies FilterDefinitionsType<OpportunityFilterKeyEnumType>

//
// Data Products definitions
//

export const DataProductFilterKeyEnum = {
    dataProductType: 'dataProductType',
    keyword: 'keyword',
    valueScore: 'valueScore',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    dataProductTeam: 'dataProductTeam',
    businessTeam: 'businessTeam',
    stakeholder: 'stakeholder',
    area: 'area',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyText: 'customPropertyText',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
    // Assessments
    assessmentOrdinal: 'assessmentOrdinal',
    assessmentNumerical: 'assessmentNumerical',
    // Dev purpose only
    createdById: 'createdById',
} as const
export type DataProductFilterKeyEnumType = keyof typeof DataProductFilterKeyEnum

export const DataProductOrderEnum = {
    dataProductType: 'dataProductType',
    title: 'title',
    valueScore: 'valueScore',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    lastCreated: 'lastCreated',
    lastUpdated: 'lastUpdated',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
} as const
export type DataProductOrderEnumType = keyof typeof DataProductOrderEnum

export const defaultDataProductOrder = {
    key: OptionKeyEnum.order,
    valueString: DataProductOrderEnum.valueScore,
    filterOperator: OrderOperatorEnum.desc,
} as const

export const DataProductGroupEnum = {
    dataProductType: 'dataProductType',
    ungrouped: 'ungrouped',
    keyword: 'keyword',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    businessTeam: 'businessTeam',
    dataProductTeam: 'dataProductTeam',
    stakeholder: 'stakeholder',
    area: 'area',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
    // Assessments
    assessmentNumerical: 'assessmentNumerical',
    assessmentOrdinal: 'assessmentOrdinal',
} as const satisfies TGroupEnumType<DataProductFilterKeyEnumType>
export type DataProductGroupEnumType = keyof typeof DataProductGroupEnum

export const dataProductFilterDefinitions = {
    [DataProductFilterKeyEnum.dataProductType]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [DataProductFilterKeyEnum.keyword]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.area]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.valueScore]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.priority]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [DataProductFilterKeyEnum.phase]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [DataProductFilterKeyEnum.customPropertySelect]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyText]: {
        [FilterOperatorEnum.contains]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.doesNotContain]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyNumber]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyDate]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isBefore]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isOnOrBefore]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isAfter]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isOnOrAfter]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyBoolean]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.boolean,
    },
    [DataProductFilterKeyEnum.owner]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.dataProductTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.businessTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.stakeholder]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.assessmentOrdinal]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.assessmentNumerical]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    // this is just for testing purposes
    [DataProductFilterKeyEnum.createdById]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string,
    },
} as const satisfies FilterDefinitionsType<DataProductFilterKeyEnumType>

//
// Helper functions and types
//

export function getFilterValueTypeByEntityType(
    entityType: EntityTypeEnum,
    key: string,
    operator: string
): EntityViewConfigValueTypeEnum {
    if (entityType === EntityTypeEnum.Opportunity) {
        return getFilterValueType<OpportunityFilterKeyEnumType>(
            opportunityFilterDefinitions,
            key as OpportunityFilterKeyEnumType,
            operator as FilterOperatorEnumType
        )
    }

    return getFilterValueType<DataProductFilterKeyEnumType>(
        dataProductFilterDefinitions,
        key as DataProductFilterKeyEnumType,
        operator as FilterOperatorEnumType
    )
}

export function getFilterValueType<Keys extends string>(
    definitions: FilterDefinitionsType<Keys>,
    key: Keys,
    operator: FilterOperatorEnumType
): EntityViewConfigValueTypeEnum {
    if (!(key in definitions)) throw new Error(`Key ${key} is not defined in provided definitions`)
    const definition = definitions[key]
    if (!(operator in definition)) throw new Error(`Operator "${operator}" is not supported for key "${key}"`)
    // Casting is safe because we check above if operator is in the definition
    return definition[operator] as EntityViewConfigValueTypeEnum
}

export function getFilterDefaultOperator<Keys extends string>(
    definitions: FilterDefinitionsType<Keys>,
    key: Keys
): FilterOperatorEnumType {
    if (!(key in definitions)) throw new Error(`Key ${key} is not defined in provided definitions`)
    const definition = definitions[key]
    return Object.keys(definition)[0] as FilterOperatorEnumType
}

// CheckedKeys verifies if TMap has all keys from TKeys
type CheckedKeys<TKeys extends string, TMap extends Record<TKeys, unknown>> = TMap

export type FilterDefinitionsType<Keys extends string> = Record<
    Keys,
    Partial<Record<FilterOperatorEnumType, EntityViewConfigValueTypeEnum>>
>

export type StringEnum<TKeys extends string> = { readonly [key in TKeys]: key }

type TGroupEnumType<FilterKeys extends string> = { ungrouped: 'ungrouped' } & Partial<Record<FilterKeys, FilterKeys>>
