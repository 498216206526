import Prisma, { EntityCoreTypeEnum } from '@prisma/client'
import {
    type RouteLocationNormalized,
    type NavigationGuardNext,
    onBeforeRouteLeave,
    type RouteLocationRaw,
} from 'vue-router'
import { onBeforeUnmount, onBeforeMount, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDialog } from './dialogs'
import { useRouter } from 'vue-router'
import type { ConfirmationOptions } from 'primevue/confirmationoptions'
import { type EntityType } from '@prisma/client'
export interface SideNavItem {
    id: string
    to: string
    translationKey: string
    icon: string
    childRoute?: string
    entityType?: EntityCoreTypeEnum
    dataProductType?: Prisma.DataProductTypeEnum
    childNodes?: SideNavItem[]
    shouldParentBeInactive?: boolean
}

export const mainNavigation: SideNavItem[] = [
    {
        id: 'inbox',
        to: '/inbox',
        translationKey: 'inbox.title',
        icon: 'inbox',
    },
    {
        id: 'mission-control',
        to: '/mission-control',
        translationKey: 'missionControl.titles.missionControl',
        icon: 'chart-arcs',
        childNodes: [
            {
                id: 'opportunity-report',
                to: '/mission-control/opportunity-report',
                translationKey: 'missionControl.titles.opportunityReport',
                icon: 'file-description',
                shouldParentBeInactive: true,
            },
            {
                id: 'data-product-report',
                to: '/mission-control/data-product-report',
                translationKey: 'missionControl.titles.dataProductReport',
                icon: 'file-description',
                shouldParentBeInactive: true,
            },
        ],
    },
    {
        id: 'opportunities',
        to: '/opportunities',
        childRoute: '/opportunity',
        translationKey: 'opportunities.title',
        icon: 'hexagonal-pyramid',
        entityType: 'Opportunity',
    },
    {
        id: 'data-products',
        to: '/data-products',
        childRoute: '/data-product',
        translationKey: 'entityTypes.dataProducts',
        icon: 'squares',
        entityType: 'DataProduct',
        dataProductType: 'SmartProduct',
        childNodes: [
            {
                id: 'smart-products',
                to: '/smart-products',
                childRoute: '/smart-product',
                translationKey: 'smartProducts.title',
                icon: 'assembly',
                entityType: 'DataProduct',
                dataProductType: 'SmartProduct',
            },
            {
                id: 'analytics-products',
                to: '/analytics-products',
                childRoute: '/analytics-product',
                translationKey: 'analyticsProducts.title',
                icon: 'hexagonal-prism',
                entityType: 'DataProduct',
                dataProductType: 'AnalyticsProduct',
            },
            {
                id: 'data-assets',
                to: '/data-assets',
                childRoute: '/data-asset',
                translationKey: 'dataAssets.title',
                icon: 'hexagon',
                entityType: 'DataProduct',
                dataProductType: 'DataAsset',
            },
            {
                id: 'tech-products',
                to: '/tech-products',
                childRoute: '/tech-product',
                translationKey: 'techProducts.title',
                icon: 'cpu',
                entityType: 'DataProduct',
                dataProductType: 'TechProduct',
            },
        ],
    },
]

export const useCurrentRoute = () => {
    const router = useRouter()

    return {
        getCurrentRouteEntry() {
            const currentRoute = router.currentRoute.value
            const entry = mainNavigation
                .flatMap((nav) => [nav, ...(nav.childNodes ?? [])])
                .find(
                    (route) =>
                        currentRoute.path.includes(route.to) ||
                        (route.childRoute && currentRoute.path.includes(route.childRoute))
                )
            return entry
        },
    }
}

export interface TabItem {
    label: string
    routeName: string
    icon?: string
    content?: string
    active?: boolean
    disabled?: boolean
}

export const useTabMenu = () => {
    const router = useRouter()

    return {
        onTabMenuChange(tabs: TabItem[], { index }: { index: number }) {
            router.replace({ name: tabs[index].routeName })
        },
        setCurrentTab(tabs: TabItem[]) {
            const route = router.currentRoute.value
            const currentTab = tabs.find((tab) => tab.routeName === route.name)
            if (currentTab) currentTab.active = true
        },
    }
}

export const useUnsavedChanges = (hasUnsavedChanges: Ref<boolean>, options?: ConfirmationOptions) => {
    const { t } = useI18n()
    const confirm = useDialog()

    const preventNav = (event: BeforeUnloadEvent) => {
        if (!hasUnsavedChanges.value) return
        event.preventDefault()
        // Chrome requires returnValue to be set
        event.returnValue = options?.header ?? t('dialog.changes.header')
    }

    onBeforeMount(() => {
        // We use the native `beforeunload` event
        window.addEventListener('beforeunload', preventNav)
    })

    // Make sure to always clean up after yourself!
    onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', preventNav)
    })

    onBeforeRouteLeave(
        async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
            if (!hasUnsavedChanges.value) {
                next(true)
            } else {
                const result = await confirm.asyncRequire({
                    ...options,
                    header: options?.header ?? t('dialog.changes.header'),
                    message: options?.message ?? t('dialog.changes.message'),
                })
                next(result)
            }
        }
    )
}
/*
 * This function is used to convert an entity type and an id to a route location.
 * It is used to navigate to the details page of an entity.
 */
export const entityTypeToRouteDeprecated = (
    entityType: Prisma.EntityTypeEnum,
    id: string | undefined
): RouteLocationRaw => {
    const routeConfig: Record<Prisma.EntityTypeEnum, RouteLocationRaw> = {
        Opportunity: {
            name: 'opportunity-details',
            params: { id },
        },
        SmartProduct: {
            name: 'smart-product-details',
            params: { id },
        },
        AnalyticsProduct: {
            name: 'analytics-product-details',
            params: { id },
        },
        DataAsset: {
            name: 'data-asset-details',
            params: { id },
        },
        TechProduct: {
            name: 'tech-product-details',
            params: { id },
        },
    }

    return routeConfig[entityType]
}

export const entityTypeToRoute = ({ routePath }: EntityType, id?: string) => ({
    name: routePath,
    params: { id },
})

export const entityTypeToListRouteDeprecated = (entityType: Prisma.EntityTypeEnum): RouteLocationRaw => {
    const routeConfig: Record<Prisma.EntityTypeEnum, RouteLocationRaw> = {
        Opportunity: {
            name: 'opportunities',
        },
        SmartProduct: {
            name: 'smart-products',
        },
        AnalyticsProduct: {
            name: 'analytics-products',
        },
        DataAsset: {
            name: 'data-assets',
        },
        TechProduct: {
            name: 'tech-products',
        },
    }

    return routeConfig[entityType]
}

export const entityTypeToListRoute = ({ routePath }: EntityType) => ({
    name: routePath,
})
